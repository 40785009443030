import React from "react";
import { Helmet } from "react-helmet";

const NotFoundPage = () => (
  <div>
    <Helmet>
      <title>404 Not Found</title>
      <meta name="description" content="Page not found" />
    </Helmet>
    <h1 style={{ color: "black" }} >404 Not Found</h1>
    <p style={{ color: "black" }}>The page you requested was not found.</p>
  </div>
);

export default NotFoundPage;